<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              v-if="$currentUserCan($permissions.PERM_VIEW_APP_ADMIN)"
              :to="{ name: 'Admin Page' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>{{ $t("LOCATIONS.LOCATIONS_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.ADMIN_MODULE") }}</span>
              </li>
              <li>
                <span>{{ $t("LOCATIONS.LOCATIONS_LIST") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="globalOrganization?.id" class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openLocationCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_LOCATIONS)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{
              $t("LOCATIONS.ADD_LOCATION")
            }}</span>
          </base-button>
        </div>
      </div>
      <div v-if="globalOrganization?.id">
        <location-list-table
          @onViewLocation="openLocationViewModal"
          @onEditLocation="openLocationEditModal"
          @onDeleteLocation="deleteLocation"
          @onAddLocation="openLocationCreateModal"
          :key="renderKey * 100"
        />

        <div
          v-if="isViewLocationModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isViewLocationModalOpened ? 'show' : 'hide']"
          :key="renderKey * 200"
        >
          <div
            class="resizable-wrapper-content"
            v-resizable-modal="'LOCATION_VIEW'"
          >
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closeLocationModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("LOCATIONS.VIEW_LOCATION") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <view-location-page
                v-if="openLocation"
                :locationId="openLocation.id"
              />
            </div>
          </div>
        </div>

        <div
          v-if="isEditLocationModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isEditLocationModalOpened ? 'show' : 'hide']"
          :key="renderKey * 300"
        >
          <div class="resizable-wrapper-content" v-resizable-modal="'LOCATION'">
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closeLocationModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("LOCATIONS.EDIT_LOCATION") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <edit-location-page
                v-if="openLocation"
                :locationId="openLocation.id"
                @onViewLocation="openLocationViewModal"
              />
            </div>
          </div>
        </div>

        <div
          v-if="isAddLocationModalOpened"
          class="resizable-wrapper"
          v-bind:class="[isAddLocationModalOpened ? 'show' : 'hide']"
          :key="renderKey * 400"
        >
          <div class="resizable-wrapper-content" v-resizable-modal="'LOCATION'">
            <div class="resizable-wrapper-content-header">
              <div class="resizable-wrapper-content-header-left">
                <button @click="closeLocationModal">
                  <i class="far fa-arrow-left"></i>
                </button>
                <h1>
                  {{ $t("LOCATIONS.ADD_LOCATION") }}
                </h1>
              </div>
              <div class="resizable-wrapper-content-header-right">
                <wrapper-expand-button></wrapper-expand-button>
              </div>
            </div>
            <div class="resizable-wrapper-content-body">
              <add-location-page
                @onViewLocation="openLocationViewModal"
                @onCloseLocationModal="closeLocationModal"
              />
            </div>
          </div>
        </div>
      </div>
      <select-an-organization-message v-if="!globalOrganization?.id" />
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import LocationListTable from "./partials/LocationListTable.vue";
import EditLocationPage from "./components/EditLocationComponent.vue";
import AddLocationPage from "./components/AddLocationComponent.vue";
import ViewLocationPage from "./components/ViewLocationComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";
import { mapGetters } from "vuex";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";

export default {
  layout: "DashboardLayout",

  components: {
    SelectAnOrganizationMessage,
    WrapperExpandButton,
    LocationListTable,
    EditLocationPage,
    AddLocationPage,
    ViewLocationPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewLocationModalOpened = false;
    let isEditLocationModalOpened = false;
    let isAddLocationModalOpened = false;
    let openLocation = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewLocationModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditLocationModalOpened = true;
      }
      openLocation = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddLocationModalOpened = true;
    }
    return {
      isViewLocationModalOpened: isViewLocationModalOpened,
      isEditLocationModalOpened: isEditLocationModalOpened,
      isAddLocationModalOpened: isAddLocationModalOpened,
      openLocation: openLocation,
      renderKey: 1,
    };
  },

  methods: {
    openLocationCreateModal() {
      this.closeLocationModal();
      this.isAddLocationModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Locations",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openLocationViewModal(location, reRender = false) {
      this.closeLocationModal();
      this.openLocation = location;
      this.isViewLocationModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Locations",
          query: { id: this.openLocation.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openLocationEditModal(location) {
      this.closeLocationModal();
      this.openLocation = location;
      this.isEditLocationModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Locations",
          query: { id: this.openLocation.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeLocationModal() {
      this.isAddLocationModalOpened = false;
      this.isViewLocationModalOpened = false;
      this.isEditLocationModalOpened = false;
      this.openLocation = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Locations",
          query: {},
        }).href
      );
    },

    async deleteLocation(location) {
      const confirmation = await swal.fire({
        text: this.$t("LOCATIONS.DELETE_THIS_LOCATION"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("locations/destroy", location.id);
          this.renderKey++;
          this.closeLocationModal();
          this.$notify({
            type: "success",
            message: this.$t("LOCATIONS.LOCATION_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
